<template>
  <div>
    <CampaignHeader
      ref="campaignHeader"
      :campaign-id="campaignId"
      :mailing-item-id="mailingItemId"
    />

    <v-card
      v-if="isLoading()"
      max-height="800"
      class="mt-4"
    >
      <v-card-text>
        <v-skeleton-loader
          type="paragraph, table"
        />
      </v-card-text>
    </v-card>
    <v-card
      v-else
      class="mt-4"
      :height="$_windowMixin_contentHeight - 36"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="!results || !results.mailingMetaData || !results.mailingMetaData.tenantCrmId"
                  icon
                  class="mr-4"
                  style="display: inline-block;"
                  @click.stop="drawer = !drawer"
                  v-on="on"
                >
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <span>Browse Mailings</span>
            </v-tooltip>
            <p
              v-if="results && results.mailingMetaData"
              class="mr-4"
              style="display: inline-block;"
            >
              {{ results.mailingMetaData.name }}
            </p>
            <v-select
              v-model="daysFromSend"
              dense
              hide-details
              :items="daysFromSendOptions"
              outlined
              :label="`Days From Send Time`"
              class="mr-4 input-max-width"
              style="display: inline-block;"
              color="primary"
              :disabled="isLoading() || (results && !results.splitData)"
            />
            <p
              class="text-subtitle-2 font-italic mr-4"
              style="display: inline-block;"
            >
              All times are displayed in local time.
            </p>
            <!-- <v-btn-toggle
              v-model="sectionToggle"
              class="mr-4"
              background-color="info"
              dark
              multiple
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-chart-bar</v-icon>
                  </v-btn>
                </template>
                <span>Show Summary</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-email-send</v-icon>
                  </v-btn>
                </template>
                <span>Show Sends</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-email-open</v-icon>
                  </v-btn>
                </template>
                <span>Show Opens</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-cursor-default</v-icon>
                  </v-btn>
                </template>
                <span>Show Clicks</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </template>
                <span>Show Link Clicks</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-chart-donut</v-icon>
                  </v-btn>
                </template>
                <span>Show Device Opens/Clicks</span>
              </v-tooltip>
            </v-btn-toggle> -->
            <!-- <v-icon
              color="success"
              large
              style="display: inline-block;"
              @click="generateReport()"
            >
              mdi-file-pdf-box
            </v-icon> -->
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-navigation-drawer
          v-model="drawer"
          width="700"
          absolute
          bottom
          temporary
        >
          <v-skeleton-loader
            v-if="loadingScheduled"
            type="list-item@20"
          />
          <v-list
            v-else
            nav
            dense
          >
            <v-list-item-group
              :value="mailingItemId"
              active-class="deep-purple--text text--accent-4"
            >
              <template
                v-for="(mailing, i) in scheduledMailings"
              >
                <v-divider
                  v-if="i > 0 && !scheduledMailings[i-1].scheduleDateLocal.isSame(mailing.scheduleDateLocal, 'date')"
                  :key="`${mailing.mailingItemId}-D`"
                />
                <v-subheader
                  v-if="i === 0 || !scheduledMailings[i-1].scheduleDateLocal.isSame(mailing.scheduleDateLocal, 'date')"
                  :key="`${mailing.mailingItemId}-T`"
                  class="text-h5 font-weight-bold"
                >
                  {{ mailing.scheduleDateLocal | displayDateFormat3 }}
                </v-subheader>
                <v-list-item
                  :key="mailing.mailingItemId"
                  :value="mailing.mailingItemId"
                >
                  <v-list-item-title
                    @click="$router.push({ name: 'MailingResultsDashboard', params: { campaignId: mailing.campaignId, mailingItemId: mailing.mailingItemId } })"
                  >
                    <v-row>
                      <v-col cols="auto">
                        <v-chip
                          :color="mailing.status === 4 ? 'green lighten-2' : 'accent'"
                        >
                          {{ mailing.scheduleDateLocal | displayTimeFormat3 }}
                        </v-chip>
                      </v-col>
                      <v-col
                        cols="auto"
                        class="align-self-center"
                      >
                        {{ mailing.campaignName }} - {{ mailing.mailingName }}
                      </v-col>
                      <v-spacer />
                      <v-col
                        v-if="mailing.campaignId === campaignId"
                        cols="auto"
                        class="align-self-center"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              mdi-email-multiple
                            </v-icon>
                          </template>
                          <span>Same Campaign</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <v-row v-if="results && !results.splitData && results.mailingMetaData">
          <v-col cols="12 pt-8">
            <span class="text-h4">Mailing has not been sent yet. Check again after {{ results.mailingMetaData.scheduleDateLocal | displayDateTimeFormat }} to start seeing results!</span>
          </v-col>
        </v-row>
        <!-- add pdf here -->
        <!-- <vue-html2pdf
          ref="html2Pdf"
          :show-layout="false"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          filename="mms-report"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="letter"
          pdf-orientation="landscape"
          :pdf-content-width="`${$_windowMixin_getContentWidth() - 24}px`"
        > -->
        <!--
        :paginate-elements-by-height="1400"
        :pdf-content-width="`${$_windowMixin_getContentWidth() - 24}px`"

      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasDownloaded="hasDownloaded()"
      -->
        <!-- <section slot="pdf-content"> -->
        <v-row v-if="results && results.splitData">
          <v-col cols="12">
            <div
              v-for="(summary, i) in results.splitData.summaries"
              :key="summary.mailingName"
              class="mt-4"
            >
              <span class="font-weight-bold">{{ $_splitMixin_splitLetters[i] }} Send Progress:</span>
              <v-progress-linear
                class="my-4"
                :value="summary.isSendComplete ? 100 : Math.round((summary.sends / summary.sendCount * 100))"
                :color="$_splitMixin_splitColors[i]"
                :height="20"
                :buffer-value="summary.isSendComplete ? 0 : Math.round((summary.sends / summary.sendCount * 100)) + 5"
                striped
                stream
              >
                {{ summary.isSendComplete ? 100 : Math.round((summary.sends / summary.sendCount * 100)) }}% Complete
              </v-progress-linear>
              <v-btn
                icon
                class="mt-5"
                :color="$_splitMixin_splitColors[i]"
                @click="getSplitHtmlDialog(results.mailingMetaData.splits[i].id, results.mailingMetaData.splits[i])"
              >
                <span
                  class="title"
                >
                  {{ $_splitMixin_indexToChar(i) }}
                </span>
              </v-btn>
              <LabelValue
                v-if="results.mailingMetaData.splits[i].distribution < 100"
                :label="`${$_splitMixin_splitLetters[i]} Distribution`"
                :value="`${results.mailingMetaData.splits[i].distribution}%`"
              />
              <LabelValue
                :label="`${$_splitMixin_splitLetters[i]} Subject`"
                :value="summary.emailSubject"
              />
              <LabelValue
                label="Sends"
                :value="summary.sends"
              />
              <LabelValue
                label="Bounces"
                :value="summary.bounces"
              />
              <LabelValue
                label="Opens"
                :value="summary.opens"
              />
              <LabelValue
                label="Clicks"
                :value="summary.clicks"
              />
              <!-- <LabelValue
                label="Blocks"
                :value="summary.blocks"
              /> -->
              <LabelValue
                label="Restrictions"
                :value="summary.restrictions"
              />
              <!-- <LabelValue
                label="Suppressions"
                :value="summary.suppressions"
              /> -->
              <v-divider />
            </div>
            <LabelValue
              label="Schedule Date"
              :value="results.splitData.summaries[0].actualScheduleDateLocal | displayDateTimeFormat"
            />
            <LabelValue
              v-if="results.mailingMetaData.winningCriteria"
              label="Winning Criteria"
              :value="$_splitMixin_winningCriteriaSelect.find(x => x.value === results.mailingMetaData.winningCriteria).text"
            />
            <LabelValue
              v-if="results.mailingMetaData.resendType"
              label="Resend Type"
              :value="$_splitMixin_resendTypeSelect.find(x => x.value === results.mailingMetaData.resendType).text"
            />
            <v-divider />
          </v-col>
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showSummary"
            cols="12"
          >
            <bar-chart
              :chart-data="results.splitData.summaryAsPercent"
              :options="getChartOptionsBar(`Summary for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`)"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showSends"
            cols="12"
          >
            <bar-chart
              :chart-data="results.splitData.sendsXTime"
              :options="getChartOptionsBar(`Sends for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`)"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showOpens"
            cols="12"
          >
            <bar-chart
              :chart-data="results.splitData.opensXTime"
              :options="getChartOptionsBar(`Gross Opens for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`)"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showClicks"
            cols="12"
          >
            <bar-chart
              :chart-data="results.splitData.clicksXTime"
              :options="getChartOptionsBar(`Gross Clicks for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`)"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showLinkClicks"
            cols="12"
          >
            <v-row>
              <v-col
                v-for="(links, i) in results.splitData.linkItems"
                :key="i"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="results.splitData.linkSelected[i]"
                  dense
                  hide-details
                  :items="linksToSelectOptions(links)"
                  outlined
                  :label="`Split ${$_splitMixin_splitLetters[i]} Links`"
                  class="input-max-width mb-4 mr-4"
                  :color="$_splitMixin_splitColors[i]"
                  :disabled="results.splitData.linksLoading[i]"
                  style="display: inline-block;"
                  @change="getSplitLinkResults($event, results.splitData, results.splitData.summaries[i].mailingName, i)"
                >
                  <template v-slot:selection="{ item, index }">
                    <span>{{ item.value }}</span>
                  </template>
                </v-select>
                <a
                  v-if="links.filter(x => x.name === results.splitData.linkSelected[i]).length > 0"
                  class=""
                  style="display: inline-block;"
                  :href="links.find(x => x.name === results.splitData.linkSelected[i]).url"
                  target="_blank"
                >
                  {{ links.find(x => x.name === results.splitData.linkSelected[i]).url }}
                </a>
                <bar-chart
                  v-if="!results.splitData.linksLoading[i]"
                  :chart-data="results.splitData.linkClicksXTime[i]"
                  :options="getChartOptionsBar(`${results.splitData.linkSelected[i] || ''} Gross Clicks for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`)"
                  class="pb-2"
                />
                <v-skeleton-loader
                  v-else
                  type="image@2"
                />
              </v-col>
            </v-row>
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showDevices"
            cols="12"
          >
            <v-icon
              color="lime darken-1"
            >
              mdi-square-rounded
            </v-icon>
            Android
            <v-icon
              color="yellow"
            >
              mdi-square-rounded
            </v-icon>
            Chrome OS
            <v-icon
              color="green lighten-2"
            >
              mdi-square-rounded
            </v-icon>
            iOS
            <v-icon
              color="amber"
            >
              mdi-square-rounded
            </v-icon>
            Linux
            <v-icon
              color="teal lighten-3"
            >
              mdi-square-rounded
            </v-icon>
            Mac OS X
            <v-icon
              color="light-blue"
            >
              mdi-square-rounded
            </v-icon>
            Windows
            <v-icon
              color="blue-grey lighten-4"
            >
              mdi-square-rounded
            </v-icon>
            Other
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showDevices"
            cols="12"
            sm="6"
          >
            <pie-chart
              :chart-data="results.splitData.userAgentOpens"
              :options="getChartOptionsPie(`Gross Opens by Device for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`, '')"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showDevices"
            cols="12"
            sm="6"
          >
            <pie-chart
              :chart-data="results.splitData.userAgentClicks"
              :options="getChartOptionsPie(`Gross Clicks by Device for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`, '')"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
        </v-row>
        <v-row v-if="results && results.sendType === 2 && !results.winnerData">
          <v-col cols="12">
            <span class="text-h4">Mailing Winner has not been sent yet. Check again after {{ results.mailingMetaData.winningScheduleDateLocal | displayDateTimeFormat }} to start seeing results!</span>
          </v-col>
        </v-row>
        <v-row v-if="results && results.sendType === 4 && results.winnerData">
          <v-col cols="12">
            <div
              v-for="(summary) in results.winnerData.summaries"
              :key="summary.mailingName"
              class="mt-4"
            >
              <span class="font-weight-bold">Winner Send Progress:</span>
              <v-progress-linear
                class="my-4"
                :value="summary.isSendComplete ? 100 : Math.round((summary.sends / summary.sendCount * 100))"
                :color="$_splitMixin_winningColor"
                :height="20"
                :buffer-value="summary.isSendComplete ? 0 : Math.round((summary.sends / summary.sendCount * 100)) + 5"
                striped
                stream
              >
                {{ summary.isSendComplete ? 100 : Math.round((summary.sends / summary.sendCount * 100)) }}% Complete
              </v-progress-linear>
              <LabelValue
                v-if="results.mailingMetaData.winningDistribution < 100"
                :label="`Winner Distribution`"
                :value="`${results.mailingMetaData.winningDistribution}%`"
              />
              <LabelValue
                :label="`Winner Subject`"
                :value="summary.emailSubject"
              />
              <LabelValue
                label="Sends"
                :value="summary.sends"
              />
              <LabelValue
                label="Bounces"
                :value="summary.bounces"
              />
              <LabelValue
                label="Opens"
                :value="summary.opens"
              />
              <LabelValue
                label="Clicks"
                :value="summary.clicks"
              />
              <!-- <LabelValue
                label="Blocks"
                :value="summary.blocks"
              /> -->
              <LabelValue
                label="Restrictions"
                :value="summary.restrictions"
              />
              <!-- <LabelValue
                label="Suppressions"
                :value="summary.suppressions"
              /> -->
              <v-divider />
            </div>
            <LabelValue
              label="Winner Schedule Date"
              :value="results.winnerData.summaries[0].actualScheduleDateLocal | displayDateTimeFormat"
            />
            <LabelValue
              v-if="results.mailingMetaData.winningCriteria"
              label="Winning Criteria"
              :value="$_splitMixin_winningCriteriaSelect.find(x => x.value === results.mailingMetaData.winningCriteria).text"
            />
            <LabelValue
              v-if="results.mailingMetaData.winningSplitLetter"
              label="Winning Split"
              :value="results.mailingMetaData.winningSplitLetter"
            />
            <LabelValue
              v-if="results.mailingMetaData.resendType"
              label="Resend Type"
              :value="$_splitMixin_resendTypeSelect.find(x => x.value === results.mailingMetaData.resendType).text"
            />
          </v-col>
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showSummary"
            cols="12"
          >
            <bar-chart
              :chart-data="results.winnerData.summaryAsPercent"
              :options="getChartOptionsBar(`Summary for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`)"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showSends"
            cols="12"
          >
            <bar-chart
              :chart-data="results.winnerData.sendsXTime"
              :options="getChartOptionsBar(`Sends for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`)"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showOpens"
            cols="12"
          >
            <bar-chart
              :chart-data="results.winnerData.opensXTime"
              :options="getChartOptionsBar(`Gross Opens for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`)"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showClicks"
            cols="12"
          >
            <bar-chart
              :chart-data="results.winnerData.clicksXTime"
              :options="getChartOptionsBar(`Gross Clicks for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`)"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showLinkClicks"
            cols="12"
          >
            <v-row>
              <v-col
                v-for="(links, i) in results.winnerData.linkItems"
                :key="i"
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="results.winnerData.linkSelected[i]"
                  dense
                  hide-details
                  :items="linksToSelectOptions(links)"
                  outlined
                  :label="`Winner Links`"
                  class="input-max-width mb-4 mr-4"
                  :color="$_splitMixin_winningColor"
                  :disabled="results.winnerData.linksLoading[i]"
                  @change="getSplitLinkResults($event, results.winnerData, results.winnerData.summaries[i].mailingName, i)"
                >
                  <template v-slot:selection="{ item, index }">
                    <span>{{ item.value }}</span>
                  </template>
                </v-select>
                <a
                  v-if="links.filter(x => x.name === results.winnerData.linkSelected[i]).length > 0"
                  class=""
                  style="display: inline-block;"
                  :href="links.find(x => x.name === results.winnerData.linkSelected[i]).url"
                  target="_blank"
                >
                  {{ links.find(x => x.name === results.winnerData.linkSelected[i]).url }}
                </a>
                <bar-chart
                  v-if="!results.winnerData.linksLoading[i]"
                  :chart-data="results.winnerData.linkClicksXTime[i]"
                  :options="getChartOptionsBar(`${results.winnerData.linkSelected[i] || ''} Gross Clicks for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`)"
                  class="pb-2"
                />
                <v-skeleton-loader
                  v-else
                  type="image@2"
                />
              </v-col>
            </v-row>
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showDevices"
            cols="12"
          >
            <v-icon
              color="lime darken-1"
            >
              mdi-square-rounded
            </v-icon>
            Android
            <v-icon
              color="yellow"
            >
              mdi-square-rounded
            </v-icon>
            Chrome OS
            <v-icon
              color="green lighten-2"
            >
              mdi-square-rounded
            </v-icon>
            iOS
            <v-icon
              color="amber"
            >
              mdi-square-rounded
            </v-icon>
            Linux
            <v-icon
              color="teal lighten-3"
            >
              mdi-square-rounded
            </v-icon>
            Mac OS X
            <v-icon
              color="light-blue"
            >
              mdi-square-rounded
            </v-icon>
            Windows
            <v-icon
              color="blue-grey lighten-4"
            >
              mdi-square-rounded
            </v-icon>
            Other
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showDevices"
            cols="12"
            sm="6"
          >
            <pie-chart
              :chart-data="results.winnerData.userAgentOpens"
              :options="getChartOptionsPie(`Gross Opens by Device for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`, '')"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
          <!-- <v-col
            v-show="showDevices"
            cols="12"
            sm="6"
          >
            <pie-chart
              :chart-data="results.winnerData.userAgentClicks"
              :options="getChartOptionsPie(`Gross Clicks by Device for ${daysFromSendOptions.find(x => x.value === daysFromSend).text}`, '')"
              class="pb-2"
            />
            <v-divider />
          </v-col> -->
          <!-- <div class="html2pdf__page-break" /> -->
        </v-row>
        <!-- </section>
        </vue-html2pdf> -->
        <!-- end pdf here -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="htmlDialog"
      scrollable
      max-width="1400"
    >
      <v-card>
        <v-card-text
          class="pa-5"
          style="max-height: 800px"
        >
          <SplitPreview
            :id="splitId"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="tertiary"
            @click="closeHtmlDialog()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
// import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment'
import { inputDateFormat } from '@/shared/constants'
import { mailingService } from '@/shared/services'
import { splitMixin } from '@/shared/mixins/mailing'
import { mapGetters } from 'vuex'
import { windowMixin } from '@/shared/mixins/general'

export default {
  name: 'MailingResultsDashboard',

  components: {
    SplitPreview: () => import('@/views/pages/components/mailing/SplitPreview'),
    CampaignHeader: () => import('@/views/pages/components/mailing/CampaignHeader'),
    LabelValue: () => import('@/components/custom/LabelValue')
    // BarChart: () => import('@/views/pages/components/charts/BarChart'),
    // PieChart: () => import('@/views/pages/components/charts/PieChart')
    // VueHtml2pdf
  },

  mixins: [splitMixin, windowMixin],

  props: {
    campaignId: {
      type: String,
      required: true
    },
    mailingItemId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      drawer: false,
      sectionToggle: [0, 1, 2, 3, 4, 5],
      loadingScheduled: false,
      scheduledMailings: [],
      htmlDialog: false,
      splitId: '',
      daysFromSend: 14,
      daysFromSendOptions: [
        { text: '1 Day', value: 1 },
        { text: '3 Days', value: 3 },
        { text: '1 Week', value: 7 },
        { text: '2 Week', value: 14 },
        { text: '1 Month', value: 30 }
      ],
      results: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true
        }
      }
    }
  },

  watch: {
    '$route': function () {
      this.getMailingResults()
    },
    daysFromSend (newValue, oldValue) {
      this.getMailingResults()
    },
    drawer (newValue, oldValue) {
      if (newValue && !this.loadingScheduled && this.scheduledMailings.length === 0) {
        this.loadScheduledMailings()
      }
    }
  },

  created () {
    this.getMailingResults()
  },

  computed: {
    ...mapGetters(['isLoading']),
    showSummary () {
      return this.sectionToggle.includes(0)
    },
    showSends () {
      return this.sectionToggle.includes(1)
    },
    showOpens () {
      return this.sectionToggle.includes(2)
    },
    showClicks () {
      return this.sectionToggle.includes(3)
    },
    showLinkClicks () {
      return this.sectionToggle.includes(4)
    },
    showDevices () {
      return this.sectionToggle.includes(5)
    }
  },

  methods: {
    getTimezoneOffset () {
      var dateString = new Date()
      return dateString.getTimezoneOffset()
    },
    async getMailingResults () {
      this.$vuetify.goTo(0)
      await mailingService.getMailingResults({ mailingItemId: this.mailingItemId, daysFromSend: this.daysFromSend, clientTimeZone: this.getTimezoneOffset() })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.results = resp
          }
        })
    },
    async getSplitLinkResults (linkName, data, mailingName, index) {
      Vue.set(data.linksLoading, index, true)
      await mailingService.getSplitLinkResults({ mailingItemId: this.mailingItemId, acousticMailingName: mailingName, linkName, daysFromSend: this.daysFromSend, clientTimeZone: this.getTimezoneOffset() }, true)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            Vue.set(data.linkClicksXTime, index, resp)
          }
        })
        .finally(() => {
          Vue.set(data.linksLoading, index, false)
        })
    },
    async loadScheduledMailings () {
      this.loadingScheduled = true
      await mailingService.getScheduledMailings(this.results.mailingMetaData.tenantCrmId, moment().add(-90, 'd').format(inputDateFormat), moment().add(1, 'd').format(inputDateFormat), true)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.scheduledMailings = resp.map((x, index) => (
              {
                index,
                ...x,
                modifiedOnLocal: x.modifiedOn ? moment(this.$_global_parseAsUtc(x.modifiedOn)).local() : null,
                scheduleDateLocal: x.scheduleDate ? moment(this.$_global_parseAsUtc(x.scheduleDate)).local() : null,
                defaulScheduleDateLocal: x.defaulScheduleDate ? moment(this.$_global_parseAsUtc(x.defaulScheduleDate)).local() : null,
                winningScheduleDateLocal: x.winningScheduleDate ? moment(this.$_global_parseAsUtc(x.winningScheduleDate)).local() : null,
                sentOnLocal: x.sentOn ? moment(this.$_global_parseAsUtc(x.sentOn)).local() : null
              }
            ))
          }
        })
        .finally(() => {
          this.loadingScheduled = false
        })
    },
    linksToSelectOptions (links) {
      return links.map(x => ({
        text: x.url ? `${x.name} (${x.url})` : x.name,
        value: x.name
      }))
    },
    getChartOptionsBar (title) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: title
        }
      }
    },
    getChartOptionsPie (title, labels) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: title
        },
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex]
              var index = tooltipItem.index
              return dataset.labels[index] + ': ' + dataset.data[index]
            }
          }
        }
      }
    },
    getSplitHtmlDialog (id) {
      this.splitId = id
      this.htmlDialog = true
    },
    closeHtmlDialog () {
      this.htmlDialog = false
    }
    // generateReport () {
    //   this.$refs.html2Pdf.generatePdf()
    // }
  }
}
</script>

<style lang="scss">
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
